<template>
  <transition name="fade">
    <div class="pop-up pop-up--gallery dark" v-if="openGallery">
      <a
        class="icon-download pop-up__download"
        download
        target="_blank"
        :href="imgActive"
      ></a>
      <a
        class="pop-up__close icon icon-close"
        @click.prevent="$emit('close'), open = true"
        href="#"
      ></a>
      <div
        v-if="btn != false"
        class="pop-up__btn-left icon icon-arrow-right"
        @click.prevent="slide('left')"
      ></div>
      <div
        v-if="btn != false"
        class="pop-up__btn-right icon icon-arrow-right"
        @click.prevent="slide('right')"
      ></div>
      <div class="pop-up__gallery">
        <transition name="fade">
          <div
            v-if="fade"
            class="pop-up__gallery__img"
            :style="'background-image: url('+ imgActive + ')'"
          ></div>
        </transition>
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
.pop-up__btn-right.icon.icon-arrow-right,
.pop-up__btn-left.icon.icon-arrow-right {
  top: 50px;
  height: calc(100% - 50px);
  z-index: 9;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>


<script>
export default {
  props: ["img", "openGallery", 'btn'],

  data() {
    return {
      imgActive: "",
      imgList: [],
      index: 0,
      fade: true,
      open: true
    };
  },


  watch:{
    img: function(){
      if(this.openGallery && this.open){
        this.open = false;
        this.index = this.img.index;
      }
      if(this.img.list[this.index]){
        this.imgActive = this.img.list[this.index].url;
      }
    }
  },

  created() {
    this.imgList = this.img.list;
  },

  methods: {
    slide(direction) {
      var app = this;
      //fade
      app.fade = false;
      setTimeout(function () {
        app.fade = true;
      }, 300);
      var indexP = this.index

      if(direction == "left") {
        this.imgList.forEach(function (item, index) {
          if(indexP == index){
            if(index == 0){
              app.index = app.imgList.length-1
              app.imgActive = app.imgList[+app.index].url
            } else{
              app.index = +app.index - 1
              app.imgActive = app.imgList[+app.index].url
            }
          }
        });
      } else {
        this.imgList.forEach(function (item, index) {
          if(indexP == index){
            if(index == app.imgList.length-1){
              app.index = 0
              app.imgActive = app.imgList[+app.index].url
            } else{
              app.index = +app.index + 1
              app.imgActive = app.imgList[+app.index].url
            }
          }
        });
      }
    },
  },
};
</script>