<template>
  <div class="container" >
    <div class="chat__content">
      <!-- <div class="chat__data"> <span>14 вересня</span></div> -->

      <div
        class="chat__block"
        :class="[chat.isMe !== '0' ? 'chat__block--you' : '', chat.file ?
          chat.file.file_name.substr(chat.file.file_name.length - 3) == 'jpg' || 
          chat.file.file_name.substr(chat.file.file_name.length - 3) == 'png' ||
          chat.file.file_name.substr(chat.file.file_name.length - 4) == 'jpeg' ? 'chat__block--img' : '' : '']"
        v-for="(chat, index) in chats"
        :key="index"
      >
        <div>
          <h4 v-if="chat.isMe == '0'">{{ chat.manager_name }}</h4>
          <div v-if="chat.file" :style="chat.isMe !== '0' ? 'display:flex;justify-content: flex-end;' : ''">
            <div
              class="file__block-chat"
              v-if="chat.file.file_name.substr(chat.file.file_name.length - 3) == 'jpg' || 
              chat.file.file_name.substr(chat.file.file_name.length - 3) == 'png' ||
              chat.file.file_name.substr(chat.file.file_name.length - 4) == 'jpeg'"
              @click="openGallery = true, imgGallery[0].url = chat.file.file_link"
            >
              <img :src="chat.file.file_link" alt="">
            </div>
            <div v-else class="file__download" style="width:100%"> 
              <a 
                download
                :href="chat.file.file_link"
                target="_blank"
              >
                <i class="icon-download"></i>{{chat.file.file_name}}
              </a>
            </div>
          </div>
          <p>{{ chat.message }}</p>
          <span @click="clickCopy($event)" :data-copy="chat.message" :class="chat.isMe !== '0' ? 'copy notMe' : 'copy'" ></span>
          <span style="margin-top: 15px"><span>{{ chatData(chat.created_at) }}</span></span>
        </div>
      </div>
    </div>
    <div class="chat__message" v-if="chats && !$root.add.is_dispatcher">
      <div class="chat-file">
        <i class="icon icon-file-2"></i>
        <input type="file" ref="file" v-on:change="addFile" />
      </div>
      <p class="chat__message-p" style="padding-left: 40px">{{ text }}</p>
      <textarea
        style="padding-left: 40px"
        :placeholder="$root.langText.input.message"
        v-model="text"
      />
      <button @click.prevent="send()"></button>
    </div>
    <Gallery
      :openGallery="openGallery"
      :btn='false'
      @close="openGallery = false"
      :img="{ list: imgGallery, index: 0 }"
    />
    <Message
      v-if="message.popup"
      :message="message"
      @close="message.popup = false"
    />
  </div>
  
</template>








<script>
import Gallery from "@/components/app/Gallery";
import Message from "@/components/app/Message";

export default {
  data: () => ({
    chats: [],
    chatList: 0,
    text: "",
    setinerval: null,
    openGallery: false,
    imgGallery: [{
      url: ''
    }],
    message: {
      status: "",
      popup: false,
      message: "",
    },

    //Title
    globalTitle: [{ title: "Чат", info: "" }],
  }),

  async created() {
    //get chat
    var data = {
      action: "get_user_messages",
      owner_type: this.$route.params.id.slice(-5),
      owner_id: this.$route.params.id.substr(
        0,
        this.$route.params.id.length - 6
      ),
      last_id: 0,
    };
    const chatList = await this.$store.dispatch("getChat", data);
    this.chats = chatList.data.messages;
    if (this.chats) {
      this.chatList = chatList.data.messages_count;

      var data2 = {
        action: "read_messages",
        read_messages: chatList.data.not_read_messages,
      };
      const newChatList = await this.$store.dispatch("getChat", data2);

      this.$root.title = this.globalTitle;
      if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
        this.$root.title[0].title =
        (this.$route.params.id.slice(-5) == "order" ? this.$root.langText.directory.orderOne : this.$root.langText.directory.quotes) +
        " №" +
        chatList.data.owner_number;
      } else{
        this.$root.title[0].title =
        (this.$route.params.id.slice(-5) == "order" ? this.$root.langText.directory.orderOtherOne : this.$root.langText.directory.quotesOther) +
        " №" +
        chatList.data.owner_number;
      }
      
      this.$root.title[0].info =
        "від " + new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(chatList.data.owner_date.replace(/-/g, "/")))
    }
    window.scrollTo(0, 10000000);
  },
  // updated() {
  //   window.scrollTo(0, 10000000);
  // },

  watch: {
    chats: function () {
      // if (this.chatList !== 0) {
        clearInterval(this.setinerval);
        var app = this;
        this.setinerval = window.setInterval(async () => {
          var data = {
            action: "get_user_messages",
            owner_type: this.$route.params.id.slice(-5),
            owner_id: this.$route.params.id.substr(
              0,
              this.$route.params.id.length - 6
            ),
            last_id: app.chats.length ? app.chats[app.chats.length - 1].id : 0,
          };
          var chatList = await app.$store.dispatch("getChat", data);
          chatList.data.messages.forEach((element) => {
            app.chats.push(element);
          });
        }, 1000);
      // }
    },
  },

  methods: {
    chatData(data) {
      return data.substr(0, data.length - 3);
    },

    async addFile(data) {
      var formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      var file = this.$refs.file.files[0];
      var data = formData;
      var id = this.$route.params.id.substr(
        0,
        this.$route.params.id.length - 6
      );
      var type = this.$route.params.id.slice(-5);
      var name = file.name;
      var typeChat = 0
      await this.$store.dispatch("addFileChat", { id, type, name, typeChat, data });


      if (this.chatList == 0) {
        var data2 = {
          action: "get_user_messages",
          owner_type: this.$route.params.id.slice(-5),
          owner_id: this.$route.params.id.substr(
            0,
            this.$route.params.id.length - 6
          ),
          last_id: 0,
        };
        const chatListUpdate = await this.$store.dispatch("getChat", data2);
        this.chats = chatListUpdate.data.messages;
        this.chatList = chatListUpdate.data.messages_count;
      }
    },
    
    clickCopy(el){
      try {
        navigator.clipboard.writeText(el.target.getAttribute("data-copy"));
        this.message.status = 'ok';
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.copy;
      } catch (e) {
        this.message.status = 'error';
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.error;
        throw e
      }
    },

    async send() {
      //send
      var data = {
        action: "send_message",
        data: {
          owner_type: this.$route.params.id.slice(-5),
          owner_id: this.$route.params.id.substr(
            0,
            this.$route.params.id.length - 6
          ),
          note: this.text,
        },
      };
      const chatList = await this.$store.dispatch("getChat", data);
      this.text = "";

      if (this.chatList == 0) {
        var data2 = {
          action: "get_user_messages",
          owner_type: this.$route.params.id.slice(-5),
          owner_id: this.$route.params.id.substr(
            0,
            this.$route.params.id.length - 6
          ),
          last_id: 0,
        };
        const chatListUpdate = await this.$store.dispatch("getChat", data2);
        this.chats = chatListUpdate.data.messages;
        this.chatList = chatListUpdate.data.messages_count;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.setinerval);
  },

  components:{
    Gallery, Message
  }
};
</script>