<template>
  <div class="container" style="padding: 0 0px">
    <div class="tabs" style="padding-top: 70px;">
      <div class="fixed-tab" v-if="$root.app == 'SoftProCrmAppRioTransTest' || $root.app == 'SoftProCrmAppRioTrans'">
        <ul class="tabs__caption">
          <li @click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">{{$root.langText.chat}}</li>
          <li @click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">{{$root.langText.input.comments}}</li>
        </ul>
      </div>
      
      <!-- tab 1 -->
      <div class="tabs__content" v-if="activetab === 1">
        <Chat />
      </div>

      <!-- tab 2 -->
      <div class="tabs__content" v-if="activetab === 2">
        <ChatComments />
      </div>


    </div>
  </div>
</template>


<style lang="scss">
@import '../../assets/chat.css';
  .chat__message{
    background: #fff;
    z-index: 9;
    bottom: 50px;
  }
  .chat__block{
    display: block;
  }
  .chat__message-p{
    padding: 15px 55px 15px 15px;
    opacity: 0;
    min-height: 55px;
    height: 100%;
    max-height: 200px;
    visibility: hidden;
    white-space: pre-wrap;
  }
  .chat__message textarea{
    display: block;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    max-height: 200px;
    overflow: auto;
    position: absolute;
    padding-top: 20px;
    top: 15px;
    left: 15px;
  }
  .title.fixed{
    position: fixed;
    top: 0;
    width: 100%;
  }
  .fixed-tab{
    position: fixed;
    top: 80px;
    left:0;
    background: #fff;
    padding: 10px 20px;
    width: 100%;
    z-index: 9;
  }
  .fixed-tab .tabs__caption{
    margin-bottom: 0;
  }
</style>



<script>
import Chat from '@/components/chat/Chat'
import ChatComments from '@/components/chat/ChatComments'


export default {
  data: () => (
    { 
      activetab: 1, 
      //Title
      // globalTitle: [{title: 'Чат', info: ''}],
    }
  ),



  created() {
    setTimeout(function(){
      var title = document.getElementsByClassName('title')
      // title[0].classList.add("fixed");
    }, 300)
  },

  components: {
    Chat, ChatComments
  },

  beforeDestroy() {
    var title = document.getElementsByClassName('title')
    // title[0].classList.remove("fixed");
  },

  metaInfo(){
    return {
      title: this.$title(this.$root.langText.chat)
    }
  },
}
</script>